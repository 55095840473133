var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"container-lg px-4 content py-5"},[_c('div',{staticClass:"col-12"},[_c('a',{staticClass:"btn btn-outline-primary mb-3",attrs:{"role":"button"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" 返回列表 ")])]),_c('div',{staticClass:"col-12 title fs-1 fw-bold"},[_vm._v("品牌公关")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jumbotron-wrapper image"},[_c('div',{staticClass:"jumbotron container-lg px-4"},[_c('div',{staticClass:"animation cover-text mt-5 pt-5 ps-0"},[_c('div',[_vm._v("和我们，做有挑战的事！")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"badge border text-primary border-primary"},[_vm._v("市场")]),_c('div',{staticClass:"ms-2 badge border text-primary border-primary"},[_vm._v(" 社会招聘 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"description mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("岗位描述")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 品牌：根据公司品牌规划，完善品牌物料，参与各类品牌传播活动的策划和执行； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 公关：根据公司年度公关策略，负责内容的搜集整理，撰写新闻稿和宣传文案，并利用媒体资源通过多种渠道进行传播，提升公司在业界的声誉； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 舆情：利用监测平台，监控敏感舆情，根据需要搜集、整理和分析舆情信息，协助做好负面信息拦截和媒体沟通支持，提升公司美誉度； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 奖项：负责公司在投资领域中各类奖项的接洽、申报和论坛活动参与； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 新媒体运营：熟悉新媒体的运营模式（微信公众号、视频号、知乎、B站等），微信公众号文章排版和发布，视频号内容策划和运营； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 根据业务需要，进行供应商搜集、整理、接洽，做好供应商管理。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"requirement mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("岗位要求")]),_c('div',{staticClass:"mt-2"},[_c('ul',{staticClass:"list-unstyled mb-4"},[_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 统招本科及以上学历，市场营销、广告、新闻传播学、公共关系等专业优先； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 有财经类媒体或者金融行业实习经验优先； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 对金融科技、一体化解决方案有深刻认知和理解； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 具有优秀的文字能力，有一定的设计美感； ")]),_c('li',{staticClass:"mb-2"},[_c('i',{staticClass:"bi bi-check text-primary"}),_vm._v(" 具有较强的沟通能力、社会活动能力、团队协作能力。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"location mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("工作城市")]),_c('div',{staticClass:"mt-2"},[_c('span',[_vm._v(" 上海 ")]),_c('span',[_vm._v(" 北京 ")]),_c('span',[_vm._v(" 深圳 ")]),_c('span',[_vm._v(" 成都 ")]),_c('span',[_vm._v(" 徐州 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"fs-4 fw-bold my-4"},[_vm._v("联系方式")]),_c('div',{staticClass:"mb-2"},[_vm._v("简历请发送至邮箱：recruit@ft.tech")]),_c('img',{attrs:{"src":require("../../assets/img/招聘求职.jpg"),"align":"center"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3 d-flex justify-content-center"},[_c('a',{staticClass:"btn btn-primary px-5",attrs:{"href":"mailto:recruit@ft.tech"}},[_vm._v(" 简历投递 ")])])
}]

export { render, staticRenderFns }